<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card">
          <ul>
            <li class="time">
              00-00
            </li>
          </ul>
        </div>
        <el-dropdown @command="switchLocale" trigger="click" class="lang-swithcer dropdown-toggle change-locale-button">
          <span class="el-dropdown-link">{{ this.lang }}</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="kz">Казахский</el-dropdown-item>
            <el-dropdown-item command="ru">Русский</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="container questions-wrap" style="display: flex; flex-direction: column">
      <div class="question-title">
        {{ $t('testing.question-number', {number: question.id}) }}
      </div>
      <div v-if="this.prompt" class="prompt-border">
        {{ $t('prompt') }}:
        <br>
        <span v-if="$i18n.locale=='ru'"> {{ this.prompt_ru }}</span>
        <span v-if="$i18n.locale=='kz'"> {{ this.prompt_kz }}</span>
      </div>
      <button v-if="url" @click="$bvModal.show('you-tube')"
              class="el-button btn btn-primary el-button--default mobile-butons"
              style="margin: auto auto 1rem;">{{ $t('watch_instruction') }}:
      </button>
      <div class="row mt-3">
        <div class="col-12 col-md-8 order-2 order-md-1">
          <div class="question-content ck-content">
            <div v-katex:auto v-html="question.answer_type_id === 7 ? 'Заполните пробелы' : question[`text_${lang}`]">
            </div>
          </div>
          <div v-if="question.file">
            <audio :src="question.file" controls></audio>
          </div>
          <form class="row">
            <!--
              Одиночный (radio) тип вопроса
            -->
            <div style="height:max-content" v-if="question.answer_type_id == 1"
                 class="px-3 d-flex justify-content-start align-items-start flex-column ck-content">
              <el-radio :label="answer.id" border class="col-12 col-md-auto mx-0 single-one"
                        v-for="(answer) in question.answers" :key="answer.id">
                <div v-katex:auto v-html="answer['text_' + lang]"></div>
              </el-radio>
            </div>
            <!--
              Множественный (checkbox) тип вопроса
            -->
            <div style="height:max-content" v-if="question.answer_type_id == 2"
                 class="px-3 d-flex justify-content-start align-items-start flex-column ck-content">
              <el-checkbox-group class="col-12 col-md-auto mx-0" v-for="(answer) in question.answers" :key="answer.id">
                <el-checkbox :label="answer.id" border>
                  <div v-katex:auto v-html="answer['text_' + lang]"></div>
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <!--
              Тип вопроса: правда/ложь
            -->
            <div style="height:max-content" v-if="question.answer_type_id == 3"
                 class="px-3 d-flex justify-content-start align-items-start flex-column ck-content"
                 v-for="(answer) in question.answers" :key="answer.id">
              <el-radio :label="answer.id" class="col-12 col-md-auto mx-0">{{ answer['text_' + lang] }}</el-radio>
            </div>
            <!--
              Свободный ответ: textarea
            -->
            <div style="height:max-content" v-if="question.answer_type_id == 4" class="col-12 ck-content">
              <el-form ref="form" class="col-12 col-md-auto">
                <el-form-item :label="$t('testing.your_answer') ">
                  <el-input type="textarea"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div style="height:max-content" v-if="question.answer_type_id == 6" class="col-12 ck-content">
              <div ref="strWithInputs" v-html="questionCustomText"></div>
            </div>
            <div style="height:max-content" v-if="question.answer_type_id == 7" class="col-12 ck-content">
              <div ref="strWithSelect" v-html="questionCustomText"></div>
            </div>


          </form>
        </div>

        <div class="col-12 col-md-4 order-1 order-mobile">

          <div style="display: flex;flex-direction: column" class="navigation">
            <button v-if="url" @click="$bvModal.show('you-tube')"
                    class="el-button btn btn-primary el-button--default pc-buttons"
                    style="margin: auto auto 1rem;">{{ $t('watch_instruction') }}:
            </button>
            <div class="card">
              <h3>{{ $t('testing.navigation') }}</h3>
              <div class="navigation-wrap clearfix">
                <a class="cursor-pointer active">1</a>
              </div>
            </div>
            <div class="row my-5 mobile-butons">
              <div class="mx-auto">
                <a @click="historyBack" class="btn btn-default"><i
                    class="el-icon-arrow-left mr-2"></i>{{ $t('testing.back-button') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5 pc-buttons">
        <div class="mx-auto">
          <a @click="historyBack" class="btn btn-default"><i
              class="el-icon-arrow-left mr-2"></i>{{ $t('testing.back-button') }}</a>
        </div>
      </div>
    </div>
    <b-modal id="you-tube" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('you-tube')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <iframe style="width: 100%" height="315" :src="url" title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Vue from 'vue';

export default {
  name: "Index",
  data() {
    return {
      prompt: "",
      prompt_ru: "",
      prompt_kz: "",
      url: "",
      question: {},
      lang: 'ru',
      questionCustomText: ''
    }
  },
  methods: {
    switchLocale(lang) {
      this.$i18n.locale = lang;
      this.lang = lang
    },
    async fetchQuestion(id) {
      this.$http.get(`${API_ROOT}/api/question-preview/${id}`).then(res => {
        if (res.body) {
          this.url = res.body.data.video_link
          this.prompt = this.$i18n.locale == 'ru' ? res.body.data.prompt_ru : res.body.data.prompt_kz
          this.prompt_ru = res.body.data.prompt_ru
          this.prompt_kz = res.body.data.prompt_kz
          this.question = res.body.data
          const {answer_type_id} = res.body.data
          switch (answer_type_id) {
            case 6:
              const arr = this.question.answers[0][`text_${this.lang}`].split('{word}')
              let newStr = ''
              for (let i = 0; i < arr.length; i++) {
                newStr += arr[i]
                if (i < arr.length - 1) {
                  newStr += `<input type="text" data-model="${i}" />`
                }
              }
              this.questionCustomText = newStr
              break;
            case 7:
              const arr2 = this.question[`text_${this.lang}`].split('{word}')
              const selects = []
              let text = ''
              for (let i = 0; i < this.question.answers.length; i++) {
                let select = `<select data-model="${i}" class="custom-answer-select">`
                this.question.answers[i].map(option => {
                  select += '<option>' + option[`text_${this.lang}`] + '</option>'
                })
                select += '</select>'
                selects.push(select)
              }
              for (let i = 0; i < arr2.length; i++) {
                text += arr2[i]
                if (i < arr2.length - 1) {
                  text += selects[i]
                }
              }
              this.questionCustomText = text
              break;
          }
        }
      }).catch(error => {
        console.log(error);
        Vue.toastr({
          message: 'Error',
          description: `${error}`,
          type: 'error'
        })
      })
    },
    historyBack() {
      window.history.back()
    }
  },
  watch: {},
  beforeCreate() {
  },
  mounted() {
    if (localStorage.getItem('test_lang')) {
      this.lang = localStorage.getItem('test_lang')
    }
    this.fetchQuestion(this.$route.params.id)
  },
  beforeDestroy() {
  },
}
</script>

<style>
.prompt-border {
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 14px;
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 158.5%;
  color: #303030;
}

.custom-answer-select {
  padding: 10px 35px 10px 15px;
  border: 1px solid #dfdfdf;
  appearance: none;
  border-radius: 6px;
  background-image: url('/images/down-filled-triangular-arrow.svg');
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: 90% 50%;
}

.lang-swithcer {
  float: right;
  padding: 15px 0;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  text-decoration: none;
}

p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.question-content p {
  font-size: 20px;
}

.el-radio__label p,
.el-checkbox__label p {
  margin: 0;
  font-size: 18px;
}

.el-radio__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  white-space: normal;
}

.el-checkbox__input,
.el-radio__input {
  display: block;
  float: left;
  margin: 3px 0 0;
}

.el-radio.is-bordered {
  padding-bottom: 12px;
}

.row {
  direction: initial;
}

.el-checkbox.is-bordered,
.el-radio.is-bordered {
  max-height: fit-content;
  height: initial;
  min-height: inherit;
}

.mobile-butons {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .order-2-mob-help {
    order: 1;
  }

  .order-mobile {
    order: 2 !important;
  }

  .pc-buttons {
    display: none !important;
  }

  .mobile-butons {
    display: block !important;
  }

}
</style>
@supports (-webkit-touch-callout: none) {
.el-checkbox.is-bordered,
.el-radio.is-bordered {
max-height: fit-content;
min-height: 40px;
}
.row {
display: initial;
}
}
.el-checkbox.is-checked,
.el-radio.is-checked{
background: #F7F8FC
}
</style>
